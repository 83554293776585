import _flickity from "flickity";
import _imagesloaded from "imagesloaded";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/*!
 * Flickity imagesLoaded v2.0.0
 * enables imagesLoaded option for Flickity
 */

/*jshint browser: true, strict: true, undef: true, unused: true */
(function (window, factory) {
  // universal module definition

  /*jshint strict: false */

  /*globals define, module, require */
  if (exports) {
    // CommonJS
    exports = factory(window, _flickity, _imagesloaded);
  } else {
    // browser global
    window.Flickity = factory(window, window.Flickity, window.imagesLoaded);
  }
})(window, function factory(window, Flickity, imagesLoaded) {
  'use strict';

  Flickity.createMethods.push("_createImagesLoaded");
  var proto = Flickity.prototype;

  proto._createImagesLoaded = function () {
    this.on("activate", (this || _global).imagesLoaded);
  };

  proto.imagesLoaded = function () {
    if (!(this || _global).options.imagesLoaded) {
      return;
    }

    var _this = this || _global;

    function onImagesLoadedProgress(instance, image) {
      var cell = _this.getParentCell(image.img);

      _this.cellSizeChange(cell && cell.element);

      if (!_this.options.freeScroll) {
        _this.positionSliderAtSelected();
      }
    }

    imagesLoaded((this || _global).slider).on("progress", onImagesLoadedProgress);
  };

  return Flickity;
});

export default exports;